import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState({
        'all_users' : '',
        'all_admin_users' : '',
        'new_users_today' : '',
        'new_users_last_month' : ''
    });
    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/dashboard`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setDashboard(res.data.stats)
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    if (loading) {
        return <h4>Loading Dashboard...</h4>
    }

    return (
        <>
            <header className="d-none d-lg-block py-5">
                <div className="container px-lg-5">
                    <div className="p-4 p-lg-5 rounded-3 text-center">
                        <div className="m-4 m-lg-5">
                            <h2 className="display-5 fw-bold">Welcome to KCW Admin Panel</h2>
                        </div>
                    </div>
                </div>
            </header>
            <section className="pt-4">
                <div className="container px-lg-5">
                    <div className="row gx-lg-5">
                        <div className="col-xl-3 col-md-6">
                            <div className="card bg-dark text-white mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">New Members Today</h5>
                                    <p className="card-text">{dashboard.new_users_today}</p>
                                </div>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <Link className="small text-white stretched-link" to="/admin/view-users">View All</Link>
                                    <div className="small text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card bg-dark text-white mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">New Members Last Month</h5>
                                    <p className="card-text">{dashboard.new_users_last_month}</p>
                                </div>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <Link className="small text-white stretched-link" to="/admin/view-users">View All</Link>
                                    <div className="small text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card bg-dark text-white mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Total Members</h5>
                                    <p className="card-text">{dashboard.all_users}</p>
                                </div>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <Link className="small text-white stretched-link" to="/admin/view-users">View All</Link>
                                    <div className="small text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card bg-dark text-white mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Total Admin Users</h5>
                                    <p className="card-text">{dashboard.all_admin_users}</p>
                                </div>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <Link className="small text-white stretched-link" to="/admin/view-admin-users">View All</Link>
                                    <div className="small text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Dashboard;