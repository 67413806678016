import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import AdminPrivateRoute from './AdminPrivateRoute';
import PublicRoute from './PublicRoute';
import Page404 from './components/errors/Page404';
import TermsConditions from './components/frontend/TermsConditions';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config){
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

function App() {
  return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <AdminPrivateRoute path='/admin' name="Admin" />
          <PublicRoute path='/' name="Home" />
          <Route path='/terms-conditions' element={<TermsConditions />} />
          <Route path="*" element={<Page404 />} />
        </Switch>
      </Router>
  );
}

export default App;
