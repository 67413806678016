import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import icon from './../../../images/icon.png';

const Login = () => {
    const history = useHistory();
    const [pending, setPending] = useState(false);
    const [loginInput, setLogin] = useState({
        name: '',
        password: '',
        error_list: []
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        setPending(true);

        const data = {
            email: loginInput.email,
            password: loginInput.password
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/login`, data).then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_id', res.data.user_id);
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    localStorage.setItem('auth_role', res.data.role);
                    localStorage.setItem('auth_image', res.data.profile_picture ? res.data.profile_picture : '');
                    swal("Success", res.data.message, "success");
                    setPending(false);
                    if (res.data.role !== 'user') {
                        history.push('/admin');
                    } else {
                        history.push('/');
                    }
                } else if (res.data.status === 401) {
                    swal("Warning", res.data.message, "warning");
                    setPending(false);
                } else {
                    setLogin({ ...loginInput, error_list: res.data.validation_errors });
                    setPending(false);
                }
            });
        });
    }

    return (
        <div className="container">
          <div className="row">
            <div className="col-10 col-lg-6 mx-auto">
              <div className="text-center pt-5">
                <img src={icon} alt="icon" width="100" />
                <h2>Log In Form</h2>
                <p>Member Login Area</p>
              </div>
              <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title text-center mb-5 fw-light fs-5">Log In</h5>
                  <form onSubmit={loginSubmit}>
                    <div className="form-floating mb-3">
                      <input type='email' id="floatingInputEmail" placeholder="name@example.com" name='email' onChange={handleInput} value={loginInput.email} className='form-control' />
                      <label htmlFor="floatingInputEmail">Email address</label>
                      <label>{loginInput.error_list.email}</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input id="floatingPassword" placeholder="Password" type='password' name='password' onChange={handleInput} value={loginInput.password} className='form-control' />
                      <label htmlFor="floatingPassword">Password</label>
                      <label>{loginInput.error_list.password}</label>
                    </div>
                    <hr/>
                    <div className="d-grid mb-2">
                      <button disabled = {pending ? 'disabled' : ''} className="btn btn-lg btn-primary btn-login fw-bold text-uppercase" type="submit">
                        <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                        Log In
                      </button>
                    </div>
                    {/* <Link className="d-block text-center mt-2 small" to="/register">Don't Have an Account? Register</Link>
                    <Link className="d-block text-center mt-2 small" to="/forgot-password">Forgot Password</Link> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default Login;