import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';

import { Statuses } from '../../../utils/Statuses';
import FilterComponent from '../../FilterComponent';

const usersColumns = [
    {
        name: 'Full Name',
        selector: row => row.full_name,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
    },
    {
        name: 'Birth Date',
        selector: row => row.birth_date,
        sortable: true,
    },
    {
        name: 'Year',
        selector: row => row.passed_out_year,
        sortable: true,
    },
    {
        name: 'Membership',
        selector: row => row.membership,
        sortable: true,
    },
    {
        name: 'Payment',
        selector: row => row.payment,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let usersData = [];

const ViewUsers = () => {
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/view-users`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setUserList(res.data.users);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    const setTableData = useCallback(async () => {
        usersData = [];
        axios.get(`/api/view-users`).then(res => {
            if (res.data.status === 200) {
                setUserList(res.data.users);
                setLoading(false);
            }
        });
    }, []);

    const deleteUser = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-user/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const restPasswordUser = (e, id) => {
        e.preventDefault();
        swal({
            title: "Reset Password",
            text: "You are going to reset the password to a default of this user",
            icon: "info",
            buttons: true,
        }).then((willReset) => {
            if (willReset) {
                axios.put(`/api/reset-password-user/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholderText="Filter Value" />
        );
    }, [filterText, resetPaginationToggle]);

    if (loading) {
        return <h4>Loading Users...</h4>
    } else {
        if (userList.length > 0) {
            usersData = userList.filter(val => 
            (val.first_name && val.first_name.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.last_name && val.last_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.email && val.email.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.phone && val.phone.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.birth_date && val.birth_date.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.passed_out_year && val.passed_out_year.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.membership && val.membership.label.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.payment_status && val.payment_status.toLowerCase().includes(filterText.toLowerCase()))
            ).filter(res => parseInt(res.role_as) === 0).map(item => {
                return ({
                    full_name :  `${item.first_name} ${item.last_name}`,
                    email :  item.email,
                    phone :  item.phone,
                    birth_date :  item.birth_date,
                    passed_out_year :  item.passed_out_year,
                    membership :    item.membership.label,
                    payment :  item.payment_status,
                    status :    Statuses.map((role, idx) => parseInt(item.status) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${parseInt(role.value) ? 'active' : 'inactive'}`}>{role.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`/admin/view-user/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                    <Link to={`/admin/edit-user/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                                    <button type='button' onClick={(e) => deleteUser(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                                    {<button type='button' onClick={(e) => restPasswordUser(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-unlock"></i></button>}
                                </div>,
                });
            });
        }
    }

    return (
        <>
            <div className='px-4'>
                <div className='card mt-4'>
                    <div className='card-header'>
                        <h4>Members</h4>
                    </div>
                    <div className='card-body'>
                        <DataTable
                            columns={usersColumns}
                            data={usersData}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewUsers;