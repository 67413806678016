import CryptoJS from "crypto-js";

const secretPass = process.env.REACT_APP_NAME;

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
  ).toString();
  return data;
};

export const decryptData = (text) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (error) {
    return 'error';
  }
};
