import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';

import FilterComponent from '../../FilterComponent';

const membershipsColumns = [
    {
        name: 'Value',
        selector: row => row.value,
        sortable: true,
    },
    {
        name: 'Label',
        selector: row => row.label,
        sortable: true,
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let membershipsData = [];

const ViewMemberships = () => {
    const [loading, setLoading] = useState(true);
    const [membershipList, setMembershipList] = useState([]);
    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/view-memberships`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setMembershipList(res.data.memberships);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    const setTableData = useCallback(async () => {
        membershipsData = []
        axios.get(`/api/view-memberships`).then(res => {
            if (res.data.status === 200) {
                setMembershipList(res.data.memberships);
                setLoading(false);
            }
        });
    }, []);

    const deleteMembership = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-membership/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholderText="Filter By Name / Slug" />
        );
    }, [filterText, resetPaginationToggle]);

    if (loading) {
        return <h4>Loading Memberships...</h4>
    } else {
        if (membershipList.length > 0) {
            membershipsData = membershipList.filter(val => 
            (val.value && val.value.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.label && val.label.toLowerCase().includes(filterText.toLowerCase()))
            ).map(item => {
                return ({
                    value   :   item.value,
                    label   :   item.label,
                    amount  :   item.amount,
                    type    :   item.type,
                    actions :   <div className="d-flex">
                                    <Link to={`/admin/edit-membership/${item.id}`} className="btn btn-outline-success btn-sm"><i className="fa fa-pencil"></i></Link>
                                    <button type='button' onClick={(e) => deleteMembership(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                                </div>,
                });
            });
        }
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Membership List
                        <Link to="/admin/add-membership" className='btn btn-primary btn-sm float-end'>Add Membership</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={membershipsColumns}
                        data={membershipsData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewMemberships;