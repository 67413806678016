import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import { Roles } from '../../../utils/Roles';

const ViewUser = (props) => {
    const userId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [userInput, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        birth_date: '',
        passed_out_year: '',
        profile_picture: '',
        role_as: '',
        accept_terms: ''
    });
    const [allCheckbox, setCheckboxes] = useState([]);

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-user/${userId}`).then(res => {
            if (res.data.status === 200) {
                setUser(res.data.user);
                setCheckboxes(res.data.user);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-users');
            }
            setLoading(false);
        });
    }, [history, userId])

    useEffect(() => {
        setFormData();
    }, [setFormData]);

    if (loading) {
        return <h4>Loading Edit User...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>View Member
                        <Link to="/admin/view-users" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' id="USER_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="first_name" className="form-label">First Name</label>
                            <input type="text" name="first_name" value={userInput.first_name} className='form-control' id='first_name' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="last_name" className="form-label">Last Name</label>
                            <input type="text" name="last_name" value={userInput.last_name} className='form-control' id='last_name' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="text" name="email"  value={userInput.email} className='form-control' id='email' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="phone" className="form-label">Phone Number</label>
                            <input type="text" name="phone" value={userInput.phone} className='form-control' id='phone' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="birth_date" className="form-label">Birth Date</label>
                            <input type="date" name="birth_date" value={userInput.birth_date} className='form-control' id='birth_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="passed_out_year" className="form-label">Passed Out Year</label>
                            <input type="number" name="passed_out_year"  value={userInput.passed_out_year} className='form-control' id='passed_out_year' min="1900" max={new Date().getFullYear()} step="1" disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="role_as" className="form-label">Role</label>
                            <select name='role_as' value={userInput.role_as} className='form-select' id='role_as' disabled>
                                <option value="">Select Role</option>
                                {Roles.map((role, idx) => <option key={idx} value={role.value}>{role.label}</option>)}
                            </select>
                        </div>
                        {userInput.bank_slip &&
                            <div className="col-md-4">
                                <label htmlFor="bank_slip" className="form-label">Bank Slip</label>
                                <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${userInput.bank_slip}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
                            </div>
                        }
                        {userInput.profile_picture && 
                            <div className="col-md-4">
                                <label htmlFor="profile_picture" className="form-label">Profile Picture</label>
                                <div><img src={`${process.env.REACT_APP_BACKEND_URL}/${userInput.profile_picture}`} className='avatar-lg mt-4' alt={userInput.id} /></div>
                            </div>
                        }
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" disabled />
                                <label htmlFor="status" className="form-check-label">Active Status (checked=Active)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='accept_terms' defaultChecked={allCheckbox.accept_terms === 1 ? true : false} className="form-check-input" id="accept_terms" disabled />
                                <label htmlFor="accept_terms" className="form-check-label">Agree to Terms and Conditions</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ViewUser;