import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const EditMembership = (props) => {
    const membershipId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [membershipInput, setMembership] = useState({
        value: '',
        label: '',
        amount: '',
        type: '',
    });
    const [errorList, setError] = useState([]);

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-membership/${membershipId}`).then(res => {
            if (res.data.status === 200) {
                setMembership(res.data.membership);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-memberships');
            }
            setLoading(false);
        });
    }, [history, membershipId])

    useEffect(() => {
        setFormData();
    }, [setFormData]);
    
    const handleInput = (e) => {
        e.persist();
        setMembership({ ...membershipInput, [e.target.name]: e.target.value });
    }

    const updateMembership = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('value', membershipInput.value);
            formData.append('label', membershipInput.label);
            formData.append('amount', membershipInput.amount);
            formData.append('type', membershipInput.type);
            formData.append('_method', 'PUT');
            
            axios.post(`/api/update-membership/${membershipId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/view-memberships');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Edit Membership...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Edit Membership
                        <Link to="/admin/view-memberships" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateMembership} id="CLIENT_FORM" className="row g-3">
                    <div className="col-md-4">
                            <label htmlFor="value" className="form-label">Value</label>
                            <input type="text" name="value" onChange={handleInput} value={membershipInput.value} className='form-control' id='value' />
                            <small className='text-danger'>{errorList.value}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="amount" className="form-label">Amount</label>
                            <input type="number" name="amount" onChange={handleInput} value={membershipInput.amount} className='form-control' id='amount' step="0.1" />
                            <small className='text-danger'>{errorList.amount}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="type" className="form-label">Type</label>
                            <select name='type' onChange={handleInput} value={membershipInput.type} className='form-select' id='type'>
                                <option value="">Select Type</option>
                                <option value="annum">Annum</option>
                                <option value="life">Life</option>
                            </select>
                            <small className='text-danger'>{errorList.type}</small>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="label" className="form-label">Label</label>
                            <input type="text" name="label" onChange={handleInput} value={membershipInput.label} className='form-control' id='label' />
                            <small className='text-danger'>{errorList.label}</small>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                                </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditMembership;