import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import icon from './../../images/icon.png'
import Avatar from 'react-avatar';

const Navbar = () => {
    const history = useHistory();
    const logoutSubmit = (e) => {
        removeShowClass();
        e.preventDefault();
        axios.post(`/api/logout`).then(res => {
          localStorage.removeItem('auth_id');
          localStorage.removeItem('auth_token');
          localStorage.removeItem('auth_name');
          localStorage.removeItem('auth_image');
          localStorage.removeItem('auth_role');
          if(res.data.status === 200) {
            swal("Success", res.data.message, "success");
            history.push('/');
          } else if (res.data.status === 402) {
            swal("Success", res.data.message, "success");
            history.push('/');
          }
        });
    }

    const removeShowClass = () => {
      document.getElementById('navbarSupportedContent').classList.remove("show");
    }

    const getName = () => {
      let fullName = localStorage.getItem('auth_name');
      let firstName = fullName.substring(0, fullName.indexOf(' '));
      if (firstName.length === 0) {
        firstName = fullName;
      }
      return firstName;
    }

    let AuthButtons = '';

    if (!localStorage.getItem('auth_token')) {
        AuthButtons = (
            <>
                <ul className='navbar-nav navbar-user ms-auto mb-2 mb-lg-0'>
                  <li className="nav-item">
                      <Link className="nav-link" to='/login' onClick={removeShowClass}>Login</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link" to='/register' onClick={removeShowClass}>Register</Link>
                  </li>
                  <li className="nav-item">
                      <Link className="nav-link" to='/forgot-password' onClick={removeShowClass}>Forgot Password</Link>
                  </li>
                </ul>
            </>
        );
    } else {
      AuthButtons = (
        <>
          <ul className="navbar-nav navbar-user ms-auto mb-2 mb-lg-0">
            {/* <li className="d-block d-lg-none nav-item">
              <Link className="nav-link" to='/profile' onClick={removeShowClass}>Update Profile</Link>
              <hr className='dropdown-link-break'/>
            </li> */}
            <li className="d-block d-lg-none nav-item">
              <Link className="nav-link" to='/change-password' onClick={removeShowClass}>Change Password</Link>
              <hr className='dropdown-link-break'/>
            </li>
            <li className="d-block d-lg-none nav-item">
              <Link className="nav-link" to="#" onClick={logoutSubmit}>Logout</Link>
            </li>

            <ul className="d-none d-lg-block navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
              <li className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <Avatar name={localStorage.getItem('auth_name')} src={localStorage.getItem('auth_image') !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${localStorage.getItem('auth_image')}` : ''} size="40" round={true} />
                  <span className='mx-2'>{getName()}</span>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-end-user" aria-labelledby="navbarDropdown">
                  {/* <li className="nav-item nav-item-user">
                    <Link className="nav-link nav-link-user text-black" to='/profile'>Update Profile</Link>
                    <hr className='dropdown-link-break'/>
                  </li> */}
                  <li className="nav-item nav-item-user">
                    <Link className="nav-link nav-link-user text-black" to='/change-password'>Change Password</Link>
                    <hr className='dropdown-link-break'/>
                  </li>
                  <li className="nav-item nav-item-user">
                    <Link className="nav-link nav-link-user text-black" to="#" onClick={logoutSubmit}>Logout</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </ul>
        </>
      );
    }

    return (
        <nav className="navbar navbar-frontend navbar-expand-lg navbar-dark bg-primary shadow sticky-top">
          <div className="container">
              <Link className="navbar-brand" to="/"> <img className='kcw-page-icon' src={icon} alt="kcw" /> </Link>
              {
                localStorage.getItem('auth_role') !== 'user' &&
                <div className="d-flex admin-portal-button">
                    <Link to={'/admin/dashboard'} className="btn btn-outline-secondary btn-sm d-flex">
                      <i className="bi bi-arrow-down-up"></i>
                      <p className='ms-0 ms-2 mb-0'>Goto Admin Portal</p>
                    </Link>
                </div>
              }
            <button id='burger-button' className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                {AuthButtons}
            </div>
          </div>
        </nav>
    );
}

export default Navbar;