import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import { Roles } from '../../../utils/Roles';

const EditUser = (props) => {
    const userId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [userInput, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        birth_date: '',
        passed_out_year: '',
        role_as: '',
        accept_terms: '',
        membership_id: '',
        membership_amount: '',
        membership_type: '',
        membership_expire_date: '',
        payment_type: '',
        payment_status: ''
    });
    const [allCheckbox, setCheckboxes] = useState([]);
    const [bankSlip, setBankSlip] = useState([]);
    const [profilePicture, setProfilePicture] = useState([]);
    const [errorList, setError] = useState([]);
    const [memberships, setMemberships] = useState([]);

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-user/${userId}`).then(res => {
            if (res.data.status === 200) {
                setUser(res.data.user);
                setBankSlip({ bank_slip: res.data.user.bank_slip });
                setProfilePicture({ profile_picture: res.data.user.profile_picture });
                setCheckboxes(res.data.user);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-users');
            }
            setLoading(false);
        });
    }, [history, userId])

    useEffect(() => {
        axios.get(`/api/all-memberships`).then(res => {
            if (res.data.status === 200) {
                setMemberships(res.data.memberships);
            }
        });
        setFormData();
    }, [setFormData]);
    
    const handleInput = (e) => {
        e.persist();
        setUser({ ...userInput, [e.target.name]: e.target.value });
    }

    const handleMembership = (e) => {
        e.persist();
        if (e.target.value !== "") {
            let membership = memberships.find(item => parseInt(item.id) === parseInt(e.target.value));
            setUser({
                ...userInput, 
                membership_id: membership.id, 
                membership_amount: membership.amount, 
                membership_type: membership.type
            });
        } else {
            setUser({
                ...userInput, 
                membership_id: '', 
                membership_amount: '', 
                membership_type: '', 
                payment_type: ''
            });
        }
    }

    const handleBankSlip = (e) => {
        e.persist();
        setBankSlip({ bank_slip: e.target.files[0] });
    }

    const handleProfilePicture = (e) => {
        e.persist();
        setProfilePicture({ profile_picture: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const updateUser = (e) => {
        e.preventDefault();
        setPending(true);
        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('first_name', userInput.first_name);
            formData.append('last_name', userInput.last_name);
            formData.append('phone', userInput.phone);
            formData.append('address', userInput.address);
            formData.append('birth_date', userInput.birth_date);
            formData.append('passed_out_year', userInput.passed_out_year);
            formData.append('role_as', userInput.role_as);
            formData.append('bank_slip', bankSlip.bank_slip);
            formData.append('profile_picture', profilePicture.profile_picture);
            formData.append('status', allCheckbox.status ? '1' : '0');
            formData.append('accept_terms', allCheckbox.accept_terms ? '1' : '0');
            formData.append('membership_id', userInput.membership_id);
            formData.append('membership_amount', userInput.membership_amount);
            formData.append('membership_type', userInput.membership_type);
            // formData.append('membership_expire_date', userInput.membership_expire_date);
            formData.append('payment_type', userInput.payment_type);
            formData.append('payment_status', userInput.payment_status);
            formData.append('_method', 'PUT');

            axios.post(`/api/update-user/${userId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/view-users');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Edit User...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Edit User
                        <Link to="/admin/view-users" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateUser} id="USER_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="first_name" className="form-label">First Name</label>
                            <input type="text" name="first_name" onChange={handleInput} value={userInput.first_name} className='form-control' id='first_name' />
                            <small className='text-danger'>{errorList.first_name}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="last_name" className="form-label">Last Name</label>
                            <input type="text" name="last_name" onChange={handleInput} value={userInput.last_name} className='form-control' id='last_name' />
                            <small className='text-danger'>{errorList.last_name}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="text" name="email" onChange={handleInput} value={userInput.email} className='form-control' id='email' disabled />
                            <small className='text-danger'>{errorList.email}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="phone" className="form-label">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInput} value={userInput.phone} className='form-control' id='phone' />
                            <small className='text-danger'>{errorList.phone}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="birth_date" className="form-label">Birth Date</label>
                            <input type="date" name="birth_date" onChange={handleInput} value={userInput.birth_date} className='form-control' id='birth_date' />
                            <small className='text-danger'>{errorList.birth_date}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="passed_out_year" className="form-label">Passed Out Year</label>
                            <input type="number" name="passed_out_year" onChange={handleInput} value={userInput.passed_out_year} className='form-control' id='passed_out_year' min="1900" max={new Date().getFullYear()} step="1" />
                            <small className='text-danger'>{errorList.passed_out_year}</small>
                        </div>
                        {/* <div className="col-12">
                            <label htmlFor="address" className="form-label">Address</label>
                            <textarea name="address" onChange={handleInput} value={userInput.address} className='form-control' id='address'></textarea>
                            <small className='text-danger'>{errorList.address}</small>
                        </div> */}
                        <div className="col-md-4">
                            <label htmlFor="bank_slip" className="form-label">Bank Slip</label>
                            <input type='file' name='bank_slip' onChange={handleBankSlip} className='form-control' id='bank_slip' />
                            {userInput.bank_slip && <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${userInput.bank_slip}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>}
                            <small className='text-danger'>{errorList.bank_slip}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="profile_picture" className="form-label">Profile Picture</label>
                            <input type='file' name='profile_picture' onChange={handleProfilePicture} className='form-control' id='profile_picture' />
                            {userInput.profile_picture && <img src={`${process.env.REACT_APP_BACKEND_URL}/${userInput.profile_picture}`} className='avatar-lg mt-4' alt={userInput.id} />}
                            <small className='text-danger'>{errorList.profile_picture}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="role_as" className="form-label">Role</label>
                            <select name='role_as' onChange={handleInput} value={userInput.role_as} className='form-select' id='role_as'>
                                <option value="">Select Role</option>
                                {Roles.map((role, idx) => <option key={idx} value={role.value}>{role.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.role_as}</small>
                        </div>
                        <div className="col-lg-3">
                            <label htmlFor="membership_id" className="form-label">Membership</label>
                            <select name='membership_id' onChange={handleMembership} value={userInput.membership_id} className='form-select' id='membership_id' disabled={userInput.payment_status === 'success' && 'disabled'}>
                                <option value="">Select Membership</option>
                                {memberships.map((item, idx) => <option key={idx} value={item.id}>{item.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.membership_id}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="membership_amount" className="form-label">Membership Amount</label>
                            <input type="text" name="membership_amount" value={userInput.membership_amount} className='form-control' id='membership_amount' disabled />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="membership_type" className="form-label">Membership Type</label>
                            <input type="text" name="membership_type" value={userInput.membership_type} className='form-control text-capitalize' id='membership_type' disabled />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="membership_expire_date" className="form-label">Membership Expire Date</label>
                            <input type="date" name="membership_expire_date" value={userInput.membership_expire_date} className='form-control' id='membership_expire_date' disabled />
                        </div>
                        <div className="col-lg-3">
                            <label htmlFor="payment_type" className="form-label">Payment Type</label>
                            <select name='payment_type' value={userInput.payment_type} className='form-select' id='payment_type' disabled>
                                <option value="">Select Payment Type</option>
                                <option value="online">Online</option>
                                <option value="bank-slip">Bank Slip</option>
                            </select>
                            <small className='text-danger'>{errorList.payment_type}</small>
                        </div>
                        <div className="col-lg-3">
                            <label htmlFor="payment_status" className="form-label">Payment Status</label>
                            <select name='payment_status' onChange={handleInput} value={userInput.payment_status} className='form-select' id='payment_status'>
                                <option value="">Select Payment Status</option>
                                <option value="pending">Pending</option>
                                <option value="success">Success</option>
                            </select>
                            <small className='text-danger'>{errorList.payment_status}</small>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Active Status (checked=Active)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='accept_terms' onChange={handleCheckbox} defaultChecked={allCheckbox.accept_terms === 1 ? true : false} className="form-check-input" id="accept_terms" />
                                <label htmlFor="accept_terms" className="form-check-label">Agree to Terms and Conditions</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditUser;