import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const AddMembership = () => {
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [membershipInput, setMembership] = useState({
        value: '',
        label: '',
        amount: '',
        type: '',
    });

    const resetForm = () => {
        setMembership({
            value: '',
            label: '',
            amount: '',
            type: '',
        });
    }

    const handleInput = (e) => {
        e.persist();
        setMembership({ ...membershipInput, [e.target.name]: e.target.value });
    }

    const submitMembership = (e) => {
        e.preventDefault();
        setPending(true);
        const formData = new FormData();
        formData.append('value', membershipInput.value);
        formData.append('label', membershipInput.label);
        formData.append('amount', membershipInput.amount);
        formData.append('type', membershipInput.type);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios({
                method: "post",
                url: `/api/store-membership`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    resetForm();
                    setError([]);
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All Fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                }
            });
        });
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Add Membership
                        <Link to="/admin/view-memberships" className='btn btn-primary btn-sm float-end'>View Memberships</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={submitMembership} id="CLIENT_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="value" className="form-label">Value</label>
                            <input type="text" name="value" onChange={handleInput} value={membershipInput.value} className='form-control' id='value' />
                            <small className='text-danger'>{errorList.value}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="amount" className="form-label">Amount</label>
                            <input type="number" name="amount" onChange={handleInput} value={membershipInput.amount} className='form-control' id='amount' step="0.1" />
                            <small className='text-danger'>{errorList.amount}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="type" className="form-label">Type</label>
                            <select name='type' onChange={handleInput} value={membershipInput.type} className='form-select' id='type'>
                                <option value="">Select Type</option>
                                <option value="annum">Annum</option>
                                <option value="life">Life</option>
                            </select>
                            <small className='text-danger'>{errorList.type}</small>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="label" className="form-label">Label</label>
                            <input type="text" name="label" onChange={handleInput} value={membershipInput.label} className='form-control' id='label' />
                            <small className='text-danger'>{errorList.label}</small>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 mt-2 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddMembership;