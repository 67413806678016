import React from 'react';
import icon from './../../images/icon.png';

const TermsConditions = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-10 col-lg-6 mx-auto py-5">
                    <img className="pb-4" src={icon} alt="icon" width="100" />
                    <h2 className="pb-4">KCW - Terms & Policies </h2>
                    <h3>Privacy Policy</h3>
                    <p>At KCW portal, we are committed to protecting the privacy and security of our customers' personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit or make a purchase on our website. By using our website, you consent to the practices described in this policy.</p>
                    <h6>Information We Collect</h6>
                    <p>When you visit our website, we may collect certain information about you, including:</p>
                    <ul>
                        <li>Personal identification information (such as your name, email address, and phone number) provided voluntarily by you during the registration or checkout process.</li>
                        <li>Payment and billing information necessary to process your orders, including credit card details, which are securely handled by trusted third-party payment processors.</li>
                        <li>Browsing information, such as your IP address, browser type, and device information, collected automatically using cookies and similar technologies.</li>
                    </ul>
                    <h6>Use of Information</h6>
                    <p>We may use the collected information for the following purposes:</p>
                    <ul>
                        <li>To process and fulfill your orders, including shipping and delivery.</li>
                        <li>To communicate with you regarding your purchases, provide customer support, and respond to inquiries or requests.</li>
                        <li>To personalize your shopping experience and present relevant product recommendations and promotions.</li>
                        <li>To improve our website, products, and services based on your feedback and browsing patterns.</li>
                        <li>To detect and prevent fraud, unauthorized activities, and abuse of our website.</li>
                    </ul>
                    <h6>Information Sharing</h6>
                    <p>We respect your privacy and do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:</p>
                    <ul>
                        <li>Trusted service providers: We may share your information with third-party service providers who assist us in operating our website, processing payments, and delivering products. These providers are contractually obligated to handle your data securely and confidentially.</li>
                        <li>Legal requirements: We may disclose your information if required to do so by law or in response to valid legal requests or orders.</li>
                    </ul>
                    <h6>Data Security</h6>
                    <p>We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                    <h6>Cookies and Tracking Technologies</h6>
                    <p>We use cookies and similar technologies to enhance your browsing experience, analyze website traffic, and gather information about your preferences and interactions with our website. You have the option to disable cookies through your browser settings, but this may limit certain features and functionality of our website.</p>
                    <h6>Changes to the Privacy Policy</h6>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page with a revised "last updated" date. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.</p>
                    <h6>Contact Us</h6>
                    <p>If you have any questions, concerns, or requests regarding our Privacy Policy or the handling of your personal information, please contact us using the information provided on our website.</p>
                    <hr />
                    <h3>Terms and Conditions</h3>
                    <p>Welcome to KCW Portal. These Terms and Conditions govern your use of our website and the purchase and sale of products from our platform. By accessing and using our website, you agree to comply with these terms. Please read them carefully before proceeding with any transactions.</p>
                    <h6>Use of the Website</h6>
                    <ol type="a">
                        <li>You must be at least 21 years old to use our website or make purchases.</li>
                        <li>You are responsible for maintaining the confidentiality of your account information, including your username and password.</li>
                        <li>You agree to provide accurate and current information during the registration and checkout process.</li>
                        <li>You may not use our website for any unlawful or unauthorized purposes.</li>
                    </ol>
                    <h6>Product Information and Pricing</h6>
                    <ol type="a">
                        <li>We strive to provide accurate product descriptions, images, and pricing information. However, we do not guarantee the accuracy or completeness of such information.</li>
                        <li>Prices are subject to change without notice. Any promotions or discounts are valid for a limited time and may be subject to additional terms and conditions.</li>
                    </ol>
                    <h6>Orders and Payments</h6>
                    <ol type="a">
                        <li>By placing an order on our website, you are making an offer to purchase the selected products.</li>
                        <li>We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing or product information, or suspected fraudulent activity.</li>
                        <li>You agree to provide valid and up-to-date payment information and authorize us to charge the total order amount, including applicable taxes and shipping fees, to your chosen payment method.</li>
                        <li>We use trusted third-party payment processors to handle your payment information securely. We do not store or have access to your full payment details.</li>
                    </ol>
                    <h6>Shipping and Delivery</h6>
                    <ol type="a">
                        <li>We will make reasonable efforts to ensure timely shipping and delivery of your orders.</li>
                        <li>Shipping and delivery times provided are estimates and may vary based on your location and other factors.</li>
                    </ol>
                    <h6>Returns and Refunds</h6>
                    <ol type="a">
                        <li>Our Returns and Refund Policy governs the process and conditions for returning products and seeking refunds. Please refer to the policy provided on our website for more information.</li>
                    </ol>
                    <h6>Intellectual Property</h6>
                    <ol type="a">
                        <li>All content and materials on our website, including but not limited to text, images, logos, and graphics, are protected by intellectual property rights and are the property of KCW Portal or its licensors.</li>
                        <li>You may not use, reproduce, distribute, or modify any content from our website without our prior written consent.</li>
                    </ol>
                    <h6>Limitation of Liability</h6>
                    <ol type="a">
                        <li>In no event shall KCW Portal, its directors, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or the purchase and use of our products.</li>
                        <li>We make no warranties or representations, express or implied, regarding the quality, accuracy, or suitability of the products offered on our website.</li>
                    </ol>
                    <h6>Amendments and Termination</h6>
                    <p>We reserve the right to modify, update, or terminate these Terms and Conditions at any time without prior notice. It is your responsibility to review these terms periodically for any changes.</p>
                    <hr />
                    <h3>Refund Policy</h3>
                    <p>Thank you for joining to the KCW club. We value your satisfaction and strive to provide you with the best online shopping experience possible. If, for any reason, you are not completely satisfied with your purchase, we are here to help.</p>
                    <h6>Refunds</h6>
                    <p>You cannot request for a refund once you apply for a membership. You can opt not to renew it.</p>
                    <h6>Processing Time</h6>
                    <p>Refunds and transfers will not be applicable.</p>
                    <h6>Contact Us</h6>
                    <p>If you have any questions or concerns regarding our refund policy, please contact our support team. We are here to assist you and ensure your shopping experience with us is enjoyable and hassle-free.</p>
                    <hr />
                </div>
            </div>
        </div>
    );
}

export default TermsConditions;