import React from 'react';

const Page401 = () => {
    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <div className='card card-body'>
                        <h2>Page 401 | Unauthorized</h2>
                        <h5>Authorization Required</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page401;