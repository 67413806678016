import React from 'react';
import { useHistory } from 'react-router-dom';
import icon from './../../../images/icon.png';

const ThankYou = (props) => {
  const history = useHistory();
  const { first_name } = (props.location && props.location.registerInput) || {};

  if (!first_name) {
    history.push('/member-registration');
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-xl-8">
          <div className="card-wrap card">
            <img className="icon-wrap" src={icon} alt="icon"/>
            <div className="content-wrap card-body p-0">
              <div className="row g-0">
                <div className="ty-image-wrap col-md-12">
                  <div className="ty-image-inner-wrap">
                    <div className="img-heading">THANK YOU</div>
                    <p className="img-text">Appreciate submitting the form to be a part of the KCW. Let’s make Kingswood Cricket proud again!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;