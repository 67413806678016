import Home from '../components/frontend/Home';
// import About from '../components/frontend/About';
// import Contact from '../components/frontend/Contact';
import Profile from '../components/frontend/Profile';
import Page403 from '../components/errors/Page403';
import Page404 from '../components/errors/Page404';
import Login from '../components/frontend/auth/Login';
// import Register from '../components/frontend/auth/Register';
import MemberRegistration from '../components/frontend/auth/MemberRegistration';
import ThankYou from '../components/frontend/auth/ThankYou';
// import ForgotPassword from '../components/frontend/auth/ForgotPassword';
// import ResetPassword from '../components/frontend/auth/ResetPassword';
import ChangePassword from '../components/frontend/auth/ChangePassword';
import Page401 from '../components/errors/Page401';
import TermsConditions from '../components/frontend/TermsConditions';

const PublicRouteList = [
    { path: '/', exact: true, name: 'Home', component: Home, allowedRoles: ['super-admin', 'admin', 'user'] },
    // { path: '/about', exact: true, name: 'About', component: About, allowedRoles: ['super-admin', 'admin', 'user'] },
    // { path: '/contact', exact: true, name: 'Contact', component: Contact, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/profile', exact: true, name: 'Profile', component: Profile, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/change-password', exact: true, name: 'ChangePassword', component: ChangePassword, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/401', exact: true, name: 'Page401', component: Page401, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/403', exact: true, name: 'Page403', component: Page403, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/404', exact: true, name: 'Page404', component: Page404, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/terms-conditions', exact: true, name: 'TermsConditions', component: TermsConditions, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/login', exact: true, name: 'Login', component: Login, allowedRoles: ['super-admin', 'admin', 'user'] },
    // { path: '/register', exact: true, name: 'Register', component: Register, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/member-registration', exact: true, name: 'MemberRegistration', component: MemberRegistration, allowedRoles: ['super-admin', 'admin', 'user'] },
    { path: '/thank-you', exact: true, name: 'ThankYou', component: ThankYou, allowedRoles: ['super-admin', 'admin', 'user'] },
    // { path: '/forgot-password', exact: true, name: 'ForgotPassword', component: ForgotPassword, allowedRoles: ['super-admin', 'admin', 'user'] },
    // { path: '/reset-password/:token/:email', exact: true, name: 'ResetPassword', component: ResetPassword, allowedRoles: ['super-admin', 'admin', 'user'] },
];

export default PublicRouteList;