import React from 'react';

const Page404 = () => {
    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <div className='card card-body'>
                        <h2>Page 404 | Page Not Found</h2>
                        <h5>Url / Page you are Searching Not Found</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;