const FilterComponent = ({ filterText, onFilter, onClear, placeholderText }) => (
    <div className="input-group" style={{ width: '400px' }}>
        <input
            id="search"
            type="text"
            placeholder={placeholderText}
            aria-label="Search Input"
            aria-describedby="button-addon2"
            value={filterText}
            onChange={onFilter}
            className="form-control"
        />
        <button onClick={onClear} className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fa fa-xmark"></i></button>
    </div>
);

export default FilterComponent;