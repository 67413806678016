import React from 'react';
import { Route } from 'react-router-dom';
import FrontendLayout from './layouts/frontend/FrontendLayout';
import Navbar from './layouts/frontend/Navbar';
import PublicRouteList from './routes/PublicRouteList';

const PublicRoute = ({...rest}) => {
    return (
        <Route {...rest}
            render = {({props, location}) =>
                (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/forgot-password" || location.pathname === "/member-registration" || location.pathname === "/thank-you" || location.pathname === "/terms-conditions" || PublicRouteList.every(obj => obj.path !== location.pathname)) && !localStorage.getItem('auth_token') ? 
                <FrontendLayout {...props} /> : 
                <div className="sb-nav-fixed">
                    <Navbar />
                    <div>
                        <FrontendLayout {...props} />
                    </div>
                </div>
            }
        />
    );
}

export default PublicRoute;