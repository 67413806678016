import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import icon from './../../images/icon.png'
import Avatar from 'react-avatar';

const Navbar = () => {
    const history = useHistory();
    const logoutSubmit = (e) => {
        e.preventDefault();
    
        axios.post(`/api/logout`).then(res => {
            localStorage.removeItem('auth_id');
            localStorage.removeItem('auth_token');
            localStorage.removeItem('auth_name');
            localStorage.removeItem('auth_image');
            localStorage.removeItem('auth_role');
            if(res.data.status === 200) {
                swal("Success", res.data.message, "success");
                history.push('/');
            } else if (res.data.status === 402) {
                swal("Success", res.data.message, "success");
                history.push('/');
            }
        });
    }

    const handleClick = (e) => {
        e.preventDefault();
        document.body.classList.toggle('sb-sidenav-toggled');
    }

    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            <Link className="navbar-brand navbar-brand-admin" to="/admin"><img className='kcw-page-icon-admin' src={icon} alt="kcw" /></Link>
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" onClick={(e) => handleClick(e)} id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
            <Link to="/" className="btn btn-outline-secondary btn-sm icon-blue d-flex">
                <i className="bi bi-arrow-down-up"></i>
                <p className='ms-0 ms-2 mb-0'>Goto App</p>
            </Link>

            <ul className="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <Avatar name={localStorage.getItem('auth_name')} src={localStorage.getItem('auth_image') !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${localStorage.getItem('auth_image')}` : ''} size="40" round={true} />
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        {/* <li><Link className="dropdown-item" to="#!">Settings</Link></li>
                        <li><Link className="dropdown-item" to="#!">Activity Log</Link></li>
                        <li><hr className="dropdown-divider" /></li> */}
                        <li><button type="button" onClick={logoutSubmit} className="dropdown-item">Logout</button></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;