import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import Avatar from 'react-avatar';

import { Roles } from '../../../utils/Roles';
import { Statuses } from '../../../utils/Statuses';
import FilterComponent from '../../FilterComponent';

const usersColumns = [
    {
        name: 'Full Name',
        selector: row => row.full_name,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Profile Picture',
        selector: row => row.profile_picture,
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let adminsData = [];

const ViewAdminUsers = () => {
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/view-users`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setUserList(res.data.users);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    const restPasswordUser = (e, id) => {
        e.preventDefault();
        swal({
            title: "Reset Password",
            text: "You are going to reset the password to a default of this user",
            icon: "info",
            buttons: true,
        }).then((willReset) => {
            if (willReset) {
                axios.put(`/api/reset-password-user/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholderText="Filter By Name / Email" />
        );
    }, [filterText, resetPaginationToggle]);

    if (loading) {
        return <h4>Loading Admin Users...</h4>
    } else {
        if (userList.length > 0) {
            adminsData = userList.filter(val => 
            (val.first_name && val.first_name.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.last_name && val.last_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.email && val.email.toLowerCase().includes(filterText.toLowerCase()))
            ).filter(res => parseInt(res.role_as) !== 0).map(item => {
                return ({
                    full_name :  `${item.first_name} ${item.last_name}`,
                    email :  item.email,
                    profile_picture :   <Avatar name={`${item.first_name} ${item.last_name}`} src={item.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${item.profile_picture}` : ''} size="40" round={true} />,
                    role :  Roles.map((role, idx) => parseInt(item.role_as) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${idx}`}>{role.label}</span> : ''),
                    status :    Statuses.map((role, idx) => parseInt(item.status) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${parseInt(role.value) ? 'active' : 'inactive'}`}>{role.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`/admin/view-user/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                    <Link to={`/admin/edit-user/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                                    {<button type='button' onClick={(e) => restPasswordUser(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-unlock"></i></button>}
                                </div>,
                });
            });
        }
    }

    return (
        <>
            <div className='px-4'>
                <div className='card mt-4'>
                    <div className='card-header'>
                        <h4>Admin Users</h4>
                    </div>
                    <div className='card-body'>
                        <DataTable
                            columns={usersColumns}
                            data={adminsData}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewAdminUsers;