import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import md5 from 'crypto-js/md5';
import { FerrisWheelSpinnerOverlay } from 'react-spinner-overlay'

import icon from './../../../images/icon.png';
import upload from './../../../images/upload_picture.png';
// import PaymentModal from '../../PaymentModal';
// import { MembershipPackages } from '../../../utils/MembershipPackages';

const MemberRegistration = () => {
    const history = useHistory();
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [bankSlip, setBankSlip] = useState([]);
    const [profilePicture, setProfilePicture] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [memberships, setMemberships] = useState([]);
    const [registerInput, setRegister] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        birth_date: '',
        passed_out_year: '',
        membership_id: '',
        membership_amount: '0',
        membership_type: '',
        payment_type: ''
    });
    const [allCheckbox, setCheckboxes] = useState([]);
    const [errorList, setError] = useState([]);
    const hiddenFileInput = useRef(null);

    useEffect(() => {
      axios.get(`/api/all-memberships`).then(res => {
        if (res.data.status === 200) {
          setMemberships(res.data.memberships);
        }
      });
      setLoading(false);
    }, []);

    const handleInput = (e) => {
        e.persist();
        setRegister({...registerInput, [e.target.name]: e.target.value});
    }

    const handleMembership = (e) => {
      e.persist();
      if (e.target.value !== "") {
        let membership = memberships.find(item => parseInt(item.id) === parseInt(e.target.value));
        // console.log(typeof e.target.value);
        setRegister({
          ...registerInput, 
          membership_id: membership.id, 
          membership_amount: membership.amount, 
          membership_type: membership.type
        });
      } else {
        setRegister({
          ...registerInput, 
          membership_id: '', 
          membership_amount: '0', 
          membership_type: '', 
          payment_type: ''
        });
      }
    }

    const handleCheckbox = (e) => {
      e.persist();
      setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
      setPending(!e.target.checked);
    }

    const handleBankSlip = (e) => {
      e.persist();
      setBankSlip({ bank_slip: e.target.files[0] });
    }

    const handleProfilePicture = (e) => {
      e.persist();
      setProfilePicture({ profile_picture: e.target.files[0] });
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage(e.target.files[0]);
      }
    }

    const handleUploadClick = event => {
      hiddenFileInput.current.click();
    };

    // const removeSelectedImage = () => {
    //   setSelectedImage();
    // };

    const registerSubmit = (e) => {
      console.log('registerSubmit', registerInput);
      // e.preventDefault();
      setPending(true);
      setSpinner(true);

      const formData = new FormData();
      formData.append('first_name', registerInput.first_name);
      formData.append('last_name', registerInput.last_name);
      formData.append('email', registerInput.email);
      formData.append('password', registerInput.password);
      formData.append('password_confirmation', registerInput.password_confirmation);
      formData.append('phone', registerInput.phone);
      formData.append('birth_date', registerInput.birth_date);
      formData.append('passed_out_year', registerInput.passed_out_year);
      formData.append('accept_terms', allCheckbox.accept_terms ? '1' : '0');
      formData.append('bank_slip', bankSlip.bank_slip);
      formData.append('profile_picture', profilePicture.profile_picture);
      formData.append('membership_id', registerInput.membership_id);
      formData.append('membership_amount', registerInput.membership_amount);
      formData.append('membership_type', registerInput.membership_type);
      formData.append('payment_type', registerInput.payment_type);

      axios.get('/sanctum/csrf-cookie').then(response => {
        axios({
          method: "post",
          url: `/api/member-registration`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
          if (res.data.status === 200) {
            console.log('registerSubmit success', res.data.message);
            swal("Success", res.data.message, "success");
            setError([]);
            setPending(false);
            setSpinner(false);
            history.push({
              pathname: '/thank-you',
              registerInput
            });
          } else {
            console.log('registerSubmit failed', res.data.errors);
            swal("All fields are mandatory", "", "error");
            setError(res.data.errors);
            setPending(false);
            setSpinner(false);
          }
        });
      });
    }
    
    const registerValidation = (e) => {
      console.log('registerValidation', registerInput);
      e.preventDefault();
      setPending(true);

      const formData = new FormData();
      formData.append('first_name', registerInput.first_name);
      formData.append('last_name', registerInput.last_name);
      formData.append('email', registerInput.email);
      formData.append('password', registerInput.password);
      formData.append('password_confirmation', registerInput.password_confirmation);
      formData.append('phone', registerInput.phone);
      formData.append('birth_date', registerInput.birth_date);
      formData.append('passed_out_year', registerInput.passed_out_year);
      formData.append('accept_terms', allCheckbox.accept_terms ? '1' : '0');
      formData.append('bank_slip', bankSlip.bank_slip);
      formData.append('profile_picture', profilePicture.profile_picture);
      formData.append('membership_id', registerInput.membership_id);
      formData.append('membership_amount', registerInput.membership_amount);
      formData.append('membership_type', registerInput.membership_type);
      formData.append('payment_type', registerInput.payment_type);

      axios.get('/sanctum/csrf-cookie').then(response => {
        axios({
          method: "post",
          url: `/api/member-registration-validation`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
          if (res.data.status === 200) {
            console.log('registerValidation success');
            setError([]);
            setPending(false);
            payHere();
          } else {
            console.log('registerValidation failed');
            swal("All fields are mandatory", "", "error");
            setError(res.data.errors);
            setPending(false);
          }
        });
      });
    }

    // Called when user completed the payment. It can be a successful payment or failure
    window.payhere.onCompleted = function onCompleted(orderId) {
      console.log("Payment completed. OrderID:" + orderId);
      console.log(orderId);
      registerSubmit();
      // onClick(orderId)
      //Note: validate the payment and show success or failure page to the customer
    };

    // Called when user closes the payment without completing
    window.payhere.onDismissed = function onDismissed() {
      //Note: Prompt user to pay again or show an error page
      console.log("Payment dismissed");
    };

    // Called when error happens when initializing payment such as invalid parameters
    window.payhere.onError = function onError(error) {
      // Note: show an error page
      console.log("Error:"  + error);
    };

    const payHere = () => {
      console.log('payHere');
      let merchantSecret  = 'MzQ2OTMwMDUxMjIzMjkyODI0ODQ5MjYxMDA2MzQxOTA0NzM1OTU5';
      let merchantId      = '1223714';
      let orderId         = registerInput.email;
      let amount          = registerInput.membership_amount;
      let hashedSecret    = md5(merchantSecret).toString().toUpperCase();
      let amountFormated  = parseFloat( amount ).toLocaleString( 'en-us', { minimumFractionDigits : 2 } ).replaceAll(',', '');
      let currency        = 'LKR';
      let hash            = md5(merchantId + orderId + amountFormated + currency + hashedSecret).toString().toUpperCase();

      let payment = {
        sandbox: true,
        merchant_id: merchantId,
        return_url: process.env.REACT_APP_FRONTEND_URL,
        cancel_url: process.env.REACT_APP_FRONTEND_URL,
        notify_url: process.env.REACT_APP_FRONTEND_URL,
        order_id: orderId,
        items: registerInput.first_name + ' ' + registerInput.last_name,
        amount: amount, 
        currency: 'LKR',
        hash: hash,
        first_name: registerInput.first_name,
        last_name: registerInput.last_name,
        email: registerInput.email,
        phone: registerInput.phone,
        address: 'KCW',
        city: 'Colombo',
        country: 'Sri Lanka',
        delivery_address: '',
        delivery_city: '',
        delivery_country: '',
        custom_1: '',
        custom_2: '',
      };
      console.log('payHere request', payment);
      window.payhere.startPayment(payment);

      // let merchantSecret  = 'MzQ2OTMwMDUxMjIzMjkyODI0ODQ5MjYxMDA2MzQxOTA0NzM1OTU5';
      // let merchantId      = '1223714';
      // let orderId         = registerInput.email;
      // let amount          = registerInput.membership_amount;
      // let hashedSecret    = md5(merchantSecret).toString().toUpperCase();
      // let amountFormated  = parseFloat( amount ).toLocaleString( 'en-us', { minimumFractionDigits : 2 } ).replaceAll(',', '');
      // let currency        = 'LKR';
      // let hash            = md5(merchantId + orderId + amountFormated + currency + hashedSecret).toString().toUpperCase();

      // const formData = new FormData();
      // formData.append('sandbox', true);
      // formData.append('merchant_id', '1223714');
      // formData.append('return_url', process.env.REACT_APP_FRONTEND_URL);
      // formData.append('cancel_url', process.env.REACT_APP_FRONTEND_URL);
      // formData.append('notify_url', process.env.REACT_APP_FRONTEND_URL);
      // formData.append('first_name', registerInput.first_name);
      // formData.append('last_name', registerInput.last_name);
      // formData.append('email', registerInput.email);
      // formData.append('phone', registerInput.phone);
      // formData.append('address', 'KCW');
      // formData.append('city', 'Colombo');
      // formData.append('country', 'Sri Lanka');
      // formData.append('order_id', orderId);
      // formData.append('items', registerInput.first_name + ' ' + registerInput.last_name);
      // formData.append('currency', 'LKR');
      // formData.append('amount', registerInput.membership_amount);
      // formData.append('hash', hash);

      // let payment = {
      //   sandbox: true,
      //   merchant_id: '1223714',
      //   return_url: process.env.REACT_APP_FRONTEND_URL,
      //   cancel_url: process.env.REACT_APP_FRONTEND_URL,
      //   notify_url: process.env.REACT_APP_FRONTEND_URL,
      //   first_name: registerInput.first_name,
      //   last_name: registerInput.last_name,
      //   email: registerInput.email,
      //   phone: registerInput.phone,
      //   address: 'KCW',
      //   city: 'Colombo',
      //   country: 'Sri Lanka',
      //   order_id: orderId,
      //   items: registerInput.first_name + ' ' + registerInput.last_name,
      //   currency: 'LKR',
      //   amount: registerInput.membership_amount, 
      //   hash: hash, 
      // };

      // axios({
      //   method: "post",
      //   url: `https://sandbox.payhere.lk/pay/checkout`,
      //   data: formData,
      //   headers: { 
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      //     "Access-Control-Allow-Credentials": true,
      //     "Content-Type": "multipart/form-data" 
      //   },
      // }).then(res => {
      //     if (res.data.status === 200) {
      //         setError([]);
      //         setPending(false);
      //         payHere();
      //     } else {
      //         swal("All fields are mandatory", "", "error");
      //         setError(res.data.errors);
      //         setPending(false);
      //     }
      // });
    }

    if (loading) {
      return <h4>Loading...</h4>
    }

    if (spinner) {
      return <FerrisWheelSpinnerOverlay loading={spinner} color='#650E20' overlayColor="rgba(64,0,0,0.2)"　size={28} />
    }

    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-10">
            <div className="card-wrap card">
              <img className="icon-wrap" src={icon} alt="icon"/>
              <div className="content-wrap card-body p-0">
                <div className="row g-0">
                  <div className="image-wrap col-md-6 d-none d-md-block">
                    <div className="image-inner-wrap">
                      <div className="img-heading">KINGSWOOD<br/> CRICKET<br/> WING</div>
                      <p className="img-text">Registration portal for the membership of Kingswood Cricket Wing.</p>
                      <p className="img-text">Let's support our boys to shine and thrive in the cricketing arena.</p>
                    </div>
                  </div>
                  <div className="form-wrap col-md-6">
                    <div className="form-heading">REGISTRATION FORM</div>
                    <form className="register-form">
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="profile_picture" className="form-label">Profile Picture</label>
                          <img src={selectedImage ? URL.createObjectURL(selectedImage) : upload} onClick={handleUploadClick} className='avatar-md' alt="" />
                          {/* {selectedImage && (
                            <button onClick={removeSelectedImage} className="delete-avatar"><i className="fa fa-xmark"></i></button>
                          )} */}
                          <input type='file' name='profile_picture' ref={hiddenFileInput} onChange={handleProfilePicture} style={{display:'none'}} className='form-control' id='profile_picture' />
                          <small className='text-danger'>{errorList.profile_picture}</small>
                        </div>
                        <div className="col-lg-8">
                          <div className="col-12 mb-3">
                            <label htmlFor="first_name">First Name</label>
                            <input id="first_name" type='text' name='first_name' onChange={handleInput} value={registerInput.first_name} className='form-control' autoFocus/>
                            <small className='text-danger'>{errorList.first_name}</small>
                          </div>
                          <div className="col-12 mb-3">
                            <label htmlFor="last_name">Last Name</label>
                            <input id="last_name" type='text' name='last_name' onChange={handleInput} value={registerInput.last_name} className='form-control'/>
                            <small className='text-danger'>{errorList.last_name}</small>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="email">Email</label>
                          <input id="email" type='email' name='email' onChange={handleInput} value={registerInput.email} className='form-control' />
                          <small className='text-danger'>{errorList.email}</small>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="phone">Phone Number</label>
                          <input type="text" name="phone" onChange={handleInput} value={registerInput.phone} className='form-control' id='phone' />
                          <small className='text-danger'>{errorList.phone}</small>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="password">Password</label>
                          <input id="password" type='password' name='password' onChange={handleInput} value={registerInput.password} className='form-control' />
                          <small className='text-danger'>{errorList.password}</small>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="password_confirmation">Confirm Password</label>
                          <input id="password_confirmation" type='password' name='password_confirmation' onChange={handleInput} value={registerInput.password_confirmation} className='form-control' />
                          <small className='text-danger'>{errorList.password_confirmation}</small>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="birth_date">Birth Date</label>
                          <input type="date" name="birth_date" onChange={handleInput} value={registerInput.birth_date} className='form-control' id='birth_date' />
                          <small className='text-danger'>{errorList.birth_date}</small>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="passed_out_year">Passed Out Year</label>
                          <input type="number" name="passed_out_year" onChange={handleInput} value={registerInput.passed_out_year} className='form-control' id='passed_out_year' min="1900" max={new Date().getFullYear()} step="1" />
                          <small className='text-danger'>{errorList.passed_out_year}</small>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <label htmlFor="membership_id" className="form-label">Membership</label>
                            <select name='membership_id' onChange={handleMembership} value={registerInput.membership_id} className='form-select' id='membership_id'>
                                <option value="">Select Membership</option>
                                {memberships.map((item, idx) => <option key={idx} value={item.id}>{item.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.membership_id}</small>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="membership_amount" className="form-label">Amount {registerInput.membership_type === 'life' ? '(One Time)' : registerInput.membership_type === 'annum' ? 'Per Year' : ''}</label>
                          {registerInput.membership_amount && <div className='pt-1'>Rs.{parseInt(registerInput.membership_amount).toLocaleString("en-US")}/-</div>}
                          {/* <input type="number" name="membership_amount" value={registerInput.membership_amount} className='form-control' disabled /> */}
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="payment_type" className="form-label">Payment Type</label>
                          <select name='payment_type' onChange={handleInput} value={registerInput.payment_type} className='form-select' id='payment_type'>
                              <option value="">Select Payment Type</option>
                              {/* <option value="online">Pay Online</option> */}
                              <option value="bank-slip">Upload Bank Slip</option>
                          </select>
                          <small className='text-danger'>{errorList.payment_type}</small>
                        </div>
                        {
                          registerInput.membership_id && 
                          <>
                            {/* <div className="col-lg-6 mb-3">
                              <label htmlFor="membership_amount" className="form-label">Amount</label>
                              <input type="number" name="membership_amount" value={registerInput.membership_amount} className='form-control' disabled />
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label htmlFor="payment_type" className="form-label">Payment Type</label>
                              <select name='payment_type' onChange={handleInput} value={registerInput.payment_type} className='form-select' id='payment_type'>
                                  <option value="">Select Payment Type</option>
                                  <option value="online">Pay Online</option>
                                  <option value="bank-slip">Upload Bank Slip</option>
                              </select>
                              <small className='text-danger'>{errorList.payment_type}</small>
                            </div> */}
                          </>
                        }
                        
                        {
                          registerInput.payment_type === 'bank-slip' &&
                          <div className="col-lg-6 mb-3">
                            <label htmlFor="bank_slip" className="form-label">Upload Bank Slip</label>
                            <input type='file' name='bank_slip' onChange={handleBankSlip} className='form-control' id='bank_slip' />
                            <small className='text-danger'>{errorList.bank_slip}</small>
                          </div>
                        }
                        
                        <div className="col-lg-12 mb-3">
                          <div className="form-check">
                            <input id="accept_terms" type="checkbox" name='accept_terms' onChange={handleCheckbox} defaultChecked={allCheckbox.accept_terms === 1 ? true : false} className="form-check-input" />
                            <label htmlFor="accept_terms" className="form-check-label">I agree the <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></label>
                          </div>
                          <small className='text-danger'>{errorList.accept_terms}</small>
                        </div>
                        <div className="d-grid mb-2">
                          {
                            // registerInput.payment_type === 'online' ?
                            // <button disabled = {pending ? 'disabled' : ''} className="btn btn-register" type='button' onClick={registerValidation}>
                            //   Pay with Payhere
                            // </button> 
                            // :
                            <button disabled = {pending ? 'disabled' : ''} className="btn btn-register" type='button' onClick={registerSubmit}>
                              Register
                            </button>
                          }
                          {/* <PaymentModal
                            registerInput={registerInput}
                            className="btn btn-register"
                            pending={pending}
                          /> */}
                        </div>
                      </div>
                    </form>                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default MemberRegistration;