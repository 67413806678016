import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const Profile = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [bankSlip, setBankSlip] = useState([]);
    const [profilePicture, setProfilePicture] = useState([]);
    const [userInput, setUser] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        birth_date: '',
        passed_out_year: '',
    });
    const [errorList, setError] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (localStorage.getItem('auth_role') === 'user') {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-profile`).then(res => {
            if (res.data.status === 200) {
                setUser(res.data.user);
                setBankSlip({ bank_slip: res.data.user.bank_slip });
                setProfilePicture({ profile_picture: res.data.user.profile_picture });
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/');
            }
            setLoading(false);
        });
    }, [history])

    useEffect(() => {
        setFormData();
    }, [setFormData]);
    
    const handleInput = (e) => {
        e.persist();
        setUser({ ...userInput, [e.target.name]: e.target.value });
    }

    const handleBankSlip = (e) => {
        e.persist();
        setBankSlip({ bank_slip: e.target.files[0] });
    }

    const handleProfilePicture = (e) => {
        e.persist();
        setProfilePicture({ profile_picture: e.target.files[0] });
    }

    const updateUser = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('first_name', userInput.first_name);
            formData.append('last_name', userInput.last_name);
            formData.append('phone', userInput.phone);
            formData.append('address', userInput.address);
            formData.append('birth_date', userInput.birth_date);
            formData.append('passed_out_year', userInput.passed_out_year);
            formData.append('bank_slip', bankSlip.bank_slip);
            formData.append('profile_picture', profilePicture.profile_picture);
            formData.append('_method', 'PUT');
            
            axios.post(`/api/update-profile`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    localStorage.setItem('auth_name', `${res.data.user.first_name} ${res.data.user.last_name}`);
                    localStorage.setItem('auth_image', res.data.user.profile_picture ? res.data.user.profile_picture : '');
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Edit User Profile...</h4>
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>Edit User Profile</h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateUser} id="USER_FORM" className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="first_name" className="form-label">First Name</label>
                            <input type="text" name="first_name" onChange={handleInput} value={userInput.first_name} className='form-control' id='first_name' />
                            <small className='text-danger'>{errorList.first_name}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="last_name" className="form-label">Last Name</label>
                            <input type="text" name="last_name" onChange={handleInput} value={userInput.last_name} className='form-control' id='last_name' />
                            <small className='text-danger'>{errorList.last_name}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="phone" className="form-label">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInput} value={userInput.phone} className='form-control' id='phone' />
                            <small className='text-danger'>{errorList.phone}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="birth_date" className="form-label">Birth Date</label>
                            <input type="date" name="birth_date" onChange={handleInput} value={userInput.birth_date} className='form-control' id='birth_date' />
                            <small className='text-danger'>{errorList.birth_date}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="passed_out_year" className="form-label">Passed Out Year</label>
                            <input type="number" name="passed_out_year" onChange={handleInput} value={userInput.passed_out_year} className='form-control' id='passed_out_year' min="1900" max={new Date().getFullYear()} step="1" />
                            <small className='text-danger'>{errorList.passed_out_year}</small>
                        </div>
                        {/* <div className="col-12">
                            <label htmlFor="address" className="form-label">Address</label>
                            <textarea name="address" onChange={handleInput} value={userInput.address} className='form-control' id='address'></textarea>
                            <small className='text-danger'>{errorList.address}</small>
                        </div> */}
                        <div className="col-md-4">
                            <label htmlFor="bank_slip" className="form-label">Bank Slip</label>
                            <input type='file' name='bank_slip' onChange={handleBankSlip} className='form-control' id='bank_slip' />
                            {userInput.bank_slip && <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${userInput.bank_slip}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>}
                            <small className='text-danger'>{errorList.bank_slip}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="profile_picture" className="form-label">Profile Picture</label>
                            <input type='file' name='profile_picture' onChange={handleProfilePicture} className='form-control' id='profile_picture' />
                            {userInput.profile_picture && <img src={`${process.env.REACT_APP_BACKEND_URL}/${userInput.profile_picture}`} className='avatar-lg mt-4' alt={userInput.id} />}
                            <small className='text-danger'>{errorList.profile_picture}</small>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Profile;