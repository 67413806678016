import React from 'react';

const Page403 = () => {
    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <div className='card card-body'>
                        <h2>Page 403 | Forbidden</h2>
                        <h5>Access Denied! You don't have Permissions to Access Requested Page</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page403;