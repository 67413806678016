import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import QRCode from "react-qr-code";
import Avatar from 'react-avatar';
import axios from 'axios';
import swal from 'sweetalert';
import { encryptData } from '../../utils/EncryptDecrypt';

const Home = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [userInput, setUser] = useState({
        id: '',
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        birth_date: '',
        passed_out_year: '',
        profile_picture: '',
        membership: '',
    });

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-profile`).then(res => {
            if (res.data.status === 200) {
                console.log(res.data.user)
                setUser(res.data.user);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/');
            }
            setLoading(false);
        });
    }, [history])

    useEffect(() => {
        setFormData();
    }, [setFormData]);

    if (loading) {
        return <h4>Loading Home...</h4>
    }

    return (
        localStorage.getItem('auth_token') ?
        <>
            {/* <header className="d-none d-lg-block pt-5">
                <div className="container px-lg-5">
                    <div className="p-4 p-lg-5 rounded-3 text-center">
                        <div className="m-4 m-lg-5">
                            <h1 className="display-5 fw-bold">Welcome to KCW</h1>
                            <p className="fs-4">Developed by Smashy Design</p>
                        </div>
                    </div>
                </div>
            </header> */}
            <section className="py-2">
                <div className="container px-lg-5">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="qr-card-container">
                                <div className="qr-card">
                                    <div className="qr-card-photo">
                                        <Avatar name={localStorage.getItem('auth_name')} src={userInput.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${userInput.profile_picture}` : ''} size="200" round={true} />
                                        <div className="caption">{userInput.membership && userInput.membership.value} Member</div>
                                    </div>
                                    <div className='qr-card-header'>
                                        <div className="qr-card-title">{userInput.first_name}</div>
                                        <div className="qr-card-subtitle">{userInput.last_name}</div>
                                        {userInput.birth_date && <div>Date of Birth : {userInput.birth_date}</div>}
                                        {userInput.passed_out_year && <div>Batch {userInput.passed_out_year}</div>}
                                    </div>
                                    <div className="qr-card-rip">
                                        <div className="circle-left"></div>
                                        <div className="rip-line"></div>
                                        <div className="circle-right"></div>
                                    </div>
                                    <div className="qr-card-qr">
                                        <div className="qr-card-qr-wrap">
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={`${process.env.REACT_APP_FRONTEND_URL}/admin/verify-user/${encryptData(userInput.id)}`}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card">
                                <div className="card-header">
                                    <Avatar name={localStorage.getItem('auth_name')} src={userInput.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${userInput.profile_picture}` : ''} size="100" round={true} />
                                </div>
                                <div className="card-body">
                                    <h4 className="card-title">{userInput.first_name} {userInput.last_name}</h4>
                                    <p className="card-text">{userInput.email}</p>
                                </div>
                                <div className="card-footer">
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={`${process.env.REACT_APP_FRONTEND_URL}/admin/verify-user/${encryptData(userInput.id)}`}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
        :
        <Redirect to='/member-registration' />
    );
}

export default Home;