import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {

    const removeBodyClass = () => {
      document.body.classList.remove('sb-sidenav-toggled');
    }


    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <Link className="nav-link" to="/admin/dashboard">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Dashboard
                    </Link>

                    <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-users">
                        <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                        Members
                    </Link>

                    <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseMembership" aria-expanded="false" aria-controls="collapseMembership">
                        <div className="sb-nav-link-icon"><i className="fas fa-briefcase"></i></div>
                        Memberships
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className="collapse" id="collapseMembership" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/add-membership">Add Membership</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-memberships">All Memberships</Link>
                        </nav>
                    </div>

                    {/* <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseRecord" aria-expanded="false" aria-controls="collapseRecord">
                        <div className="sb-nav-link-icon"><i className="fas fa-briefcase"></i></div>
                        Records
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className="collapse" id="collapseRecord" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/add-record">Add Record</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-records">All Records</Link>
                        </nav>
                    </div>                     */}
                </div>
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">Logged in as:</div>
                {localStorage.getItem('auth_name')}
            </div>
        </nav>
    );
}

export default Sidebar;