import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { decryptData } from '../../../utils/EncryptDecrypt';

const VerifyUser = (props) => {
    const userId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [userInput, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        birth_date: '',
        passed_out_year: '',
        profile_picture: '',
        role_as: '',
        accept_terms: ''
    });

    const setFormData = useCallback(async () => {
        let decryptId = decryptData(userId);
        if (decryptId !== 'error') {
            axios.get(`/api/edit-user/${decryptData(userId)}`).then(res => {
                if (res.data.status === 200) {
                    setUser(res.data.user);
                    setIsValid(true);
                } else if (res.data.status === 422) {
                    swal("Error", res.data.message, "error");
                    setIsValid(false);
                    // history.push('/admin/view-users');
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    setIsValid(false);
                    // history.push('/admin/view-users');
                }
                setLoading(false);
            });
        } else {
            swal("Error", "Invalid ID", "error");
            setIsValid(false);
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, userId]);
    
    

    useEffect(() => {
        setFormData();
    }, [setFormData]);

    if (loading) {
        return <h4>Loading Verify User...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Verify Member
                        <Link to="/admin/view-users" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    {   
                        isValid ? 
                        <>
                            <p>Valid Member</p>
                            <p>Name : {userInput && `${userInput.first_name} ${userInput.last_name}`}</p>
                            <p>Email : {userInput && `${userInput.email}`}</p>
                        </> 
                        :
                        <>
                            <p>Invalid Member</p>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default VerifyUser;