import Dashboard from '../components/admin/Dashboard';
import Profile from '../components/admin/Profile';
import ViewAdminUsers from '../components/admin/user/ViewAdminUsers';
import ViewUsers from '../components/admin/user/ViewUsers';
import EditUser from '../components/admin/user/EditUser';
import ViewUser from '../components/admin/user/ViewUser';
import VerifyUser from '../components/admin/user/VerifyUser';
import AddMembership from '../components/admin/membership/AddMembership';
import ViewMemberships from '../components/admin/membership/ViewMemberships';
import EditMembership from '../components/admin/membership/EditMembership';

const Routes = [
    { path: '/admin', exact: true, name: 'Admin' },
    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/profile', exact: true, name: 'Profile', component: Profile, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/view-admin-users', exact: true, name: 'ViewAdminUsers', component: ViewAdminUsers, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/view-users', exact: true, name: 'ViewUsers', component: ViewUsers, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/edit-user/:id', exact: true, name: 'EditUser', component: EditUser, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/view-user/:id', exact: true, name: 'ViewUser', component: ViewUser, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/verify-user/:id', exact: true, name: 'VerifyUser', component: VerifyUser, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/add-membership', exact: true, name: 'AddMembership', component: AddMembership, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/view-memberships', exact: true, name: 'ViewMemberships', component: ViewMemberships, allowedRoles: ['super-admin', 'admin'] },
    { path: '/admin/edit-membership/:id', exact: true, name: 'EditMembership', component: EditMembership, allowedRoles: ['super-admin', 'admin'] },
];

export default Routes;